import { default as chats8tX97ISc0pMeta } from "/vercel/path1/pages/chats.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as notificationsy6nBJLq02bMeta } from "/vercel/path1/pages/notifications.vue?macro=true";
import { default as authorizebnkd5xISTkMeta } from "/vercel/path1/pages/oauth/v2/authorize.vue?macro=true";
import { default as _91page_93QFAXcUvdGZMeta } from "/vercel/path1/pages/pages/[page].vue?macro=true";
import { default as _91post_93eBhzl7cTiVMeta } from "/vercel/path1/pages/posts/[post].vue?macro=true";
import { default as createEXSxUr2OD9Meta } from "/vercel/path1/pages/posts/create.vue?macro=true";
import { default as _91id_93NUy5CBoIkwMeta } from "/vercel/path1/pages/profiles/[id].vue?macro=true";
import { default as saved_45posts9ZITOR8jYaMeta } from "/vercel/path1/pages/saved-posts.vue?macro=true";
import { default as settingswUo1caaDNzMeta } from "/vercel/path1/pages/settings.vue?macro=true";
import { default as shared7BnVRp4DfMeta } from "/vercel/path1/pages/share.vue?macro=true";
import { default as signup23VFp8Ye1dMeta } from "/vercel/path1/pages/signup.vue?macro=true";
export default [
  {
    name: chats8tX97ISc0pMeta?.name ?? "chats",
    path: chats8tX97ISc0pMeta?.path ?? "/chats",
    meta: chats8tX97ISc0pMeta || {},
    alias: chats8tX97ISc0pMeta?.alias || [],
    redirect: chats8tX97ISc0pMeta?.redirect,
    component: () => import("/vercel/path1/pages/chats.vue").then(m => m.default || m)
  },
  {
    name: indexzhmolsBfabMeta?.name ?? "index",
    path: indexzhmolsBfabMeta?.path ?? "/",
    meta: indexzhmolsBfabMeta || {},
    alias: indexzhmolsBfabMeta?.alias || [],
    redirect: indexzhmolsBfabMeta?.redirect,
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsy6nBJLq02bMeta?.name ?? "notifications",
    path: notificationsy6nBJLq02bMeta?.path ?? "/notifications",
    meta: notificationsy6nBJLq02bMeta || {},
    alias: notificationsy6nBJLq02bMeta?.alias || [],
    redirect: notificationsy6nBJLq02bMeta?.redirect,
    component: () => import("/vercel/path1/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: authorizebnkd5xISTkMeta?.name ?? "oauth-v2-authorize",
    path: authorizebnkd5xISTkMeta?.path ?? "/oauth/v2/authorize",
    meta: authorizebnkd5xISTkMeta || {},
    alias: authorizebnkd5xISTkMeta?.alias || [],
    redirect: authorizebnkd5xISTkMeta?.redirect,
    component: () => import("/vercel/path1/pages/oauth/v2/authorize.vue").then(m => m.default || m)
  },
  {
    name: _91page_93QFAXcUvdGZMeta?.name ?? "pages-page",
    path: _91page_93QFAXcUvdGZMeta?.path ?? "/pages/:page()",
    meta: _91page_93QFAXcUvdGZMeta || {},
    alias: _91page_93QFAXcUvdGZMeta?.alias || [],
    redirect: _91page_93QFAXcUvdGZMeta?.redirect,
    component: () => import("/vercel/path1/pages/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: _91post_93eBhzl7cTiVMeta?.name ?? "posts-post",
    path: _91post_93eBhzl7cTiVMeta?.path ?? "/posts/:post()",
    meta: _91post_93eBhzl7cTiVMeta || {},
    alias: _91post_93eBhzl7cTiVMeta?.alias || [],
    redirect: _91post_93eBhzl7cTiVMeta?.redirect,
    component: () => import("/vercel/path1/pages/posts/[post].vue").then(m => m.default || m)
  },
  {
    name: createEXSxUr2OD9Meta?.name ?? "posts-create",
    path: createEXSxUr2OD9Meta?.path ?? "/posts/create",
    meta: createEXSxUr2OD9Meta || {},
    alias: createEXSxUr2OD9Meta?.alias || [],
    redirect: createEXSxUr2OD9Meta?.redirect,
    component: () => import("/vercel/path1/pages/posts/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NUy5CBoIkwMeta?.name ?? "profiles-id",
    path: _91id_93NUy5CBoIkwMeta?.path ?? "/profiles/:id()",
    meta: _91id_93NUy5CBoIkwMeta || {},
    alias: _91id_93NUy5CBoIkwMeta?.alias || [],
    redirect: _91id_93NUy5CBoIkwMeta?.redirect,
    component: () => import("/vercel/path1/pages/profiles/[id].vue").then(m => m.default || m)
  },
  {
    name: saved_45posts9ZITOR8jYaMeta?.name ?? "saved-posts",
    path: saved_45posts9ZITOR8jYaMeta?.path ?? "/saved-posts",
    meta: saved_45posts9ZITOR8jYaMeta || {},
    alias: saved_45posts9ZITOR8jYaMeta?.alias || [],
    redirect: saved_45posts9ZITOR8jYaMeta?.redirect,
    component: () => import("/vercel/path1/pages/saved-posts.vue").then(m => m.default || m)
  },
  {
    name: settingswUo1caaDNzMeta?.name ?? "settings",
    path: settingswUo1caaDNzMeta?.path ?? "/settings",
    meta: settingswUo1caaDNzMeta || {},
    alias: settingswUo1caaDNzMeta?.alias || [],
    redirect: settingswUo1caaDNzMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: shared7BnVRp4DfMeta?.name ?? "share",
    path: shared7BnVRp4DfMeta?.path ?? "/share",
    meta: shared7BnVRp4DfMeta || {},
    alias: shared7BnVRp4DfMeta?.alias || [],
    redirect: shared7BnVRp4DfMeta?.redirect,
    component: () => import("/vercel/path1/pages/share.vue").then(m => m.default || m)
  },
  {
    name: signup23VFp8Ye1dMeta?.name ?? "signup",
    path: signup23VFp8Ye1dMeta?.path ?? "/signup",
    meta: signup23VFp8Ye1dMeta || {},
    alias: signup23VFp8Ye1dMeta?.alias || [],
    redirect: signup23VFp8Ye1dMeta?.redirect,
    component: () => import("/vercel/path1/pages/signup.vue").then(m => m.default || m)
  }
]