<template>
  <div>
    <NuxtLayout
        :name="isDesktop ? 'default' : 'fullscreen'"
        :title="`Coś poszło nie tak...`"
        :options="[[{label: 'Feedback', icon: 'i-ri-feedback-line', to: `/report/?payload[error]=1`}]]"
    >
      <div class="text-center items-center justify-center space-y-8 place-content-center h-full mt-4">
        <h2 v-if="!!error?.statusCode" class="flex-inline text-8xl font-extrabold text-gray-800 dark:text-white line-clamp-1">
          {{ error.statusCode }}
        </h2>
        <p class="flex-inline text-lg text-gray-500 dark:text-gray-400 whitespace-pre-line">
          <span v-if="404 === error?.statusCode">Strona <UBadge variant="soft" color="orange">{{ $route.path }}</UBadge> nie istnieje.</span>
          <span v-else>Wystąpił nieoczekiwany błąd.</span>
        </p>
        <UButton icon="i-ri-arrow-go-back-line" label="Wróć na stronę główną" size="lg" @click="redirectToHome"/>
        <div class="hidden">{{error}}</div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const {isDesktop} = useDevice();
const error = useError();

const redirectToHome = () => {
  clearError({
    redirect: '/',
  });
};

useSeoMeta({
  title: 'Coś poszło nie tak...',
})
</script>
