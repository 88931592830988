import {RouteLocationNormalized} from "vue-router";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const config = useRuntimeConfig();

    if (to.path.endsWith('/')) {
        return;
    }

    const url = new URL(to.fullPath, config.public.origin);

    url.pathname += '/';

    return navigateTo(
        `${url.pathname}${url.search}${url.hash}`,
        {redirectCode: 308},
    );
});
