import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Fdvg3o8568 from "/vercel/path1/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/vercel/path1/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import pwa_client_Eorgdr12jA from "/vercel/path1/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_AOuQ1DYzjk from "/vercel/path1/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import defaults_9rtUot57OJ from "/vercel/path1/node_modules/@nuxtseo/module/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/vercel/path1/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/vercel/path1/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import script_YrMPz5ICtR from "/vercel/path1/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_t2GMTTFnMT from "/vercel/path1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import colors_244lXBzhnM from "/vercel/path1/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/vercel/path1/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_Fdvg3o8568,
  plugin_8SbxDRbG6Y,
  pwa_client_Eorgdr12jA,
  plugin_AOuQ1DYzjk,
  defaults_9rtUot57OJ,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  script_YrMPz5ICtR,
  plugin_t2GMTTFnMT,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  sentry_client_shVUlIjFLk
]