import {defineNuxtPlugin, useRouter, useRuntimeConfig} from '#app';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const router = useRouter();

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: config.public.sentryDsn,
        environment: config.public.environment,
        release: config.public.release,
        sampleRate: 1,
        tracesSampleRate: 0.1,
        integrations: [new Integrations.BrowserTracing()],
    });

    return {
        provide: {
            sentry: Sentry,
        },
    };
});
