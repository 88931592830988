<template>
  <div id="zgubidoo">
    <VitePwaManifest/>
    <NuxtLoadingIndicator/>
    <NuxtPage/>
    <UNotifications/>
  </div>
</template>

<script setup lang="ts">
const {$pwa} = useNuxtApp();
const toast = useToast();

onMounted(() => {
  if ($pwa.offlineReady) {
    toast.add({title: 'App ready to work offline!', color: 'primary'});
  }

  if ($pwa.needRefresh) {
    toast.add({
          title: 'New content available, click on reload button to update.',
          color: 'orange',
          icon: 'i-ri-refresh-line',
          actions: [{
            label: 'Reload',
            click: () => {
              $pwa.updateServiceWorker();
            },
          }],
        },
    );
  }
});
</script>
