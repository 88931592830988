import {RouteLocationNormalized} from "vue-router";

const SECURED_ROUTE_NAMES: string[] = [
    'chats',
    'posts-create',
    'profiles-id',
    'saved-posts',
];

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const {status, signIn} = useAuth();

    if ('authenticated' === status.value) {
        if ('1' == to.query?.redirectAuthenticated) {
            return to.query?.redirectAuthenticatedPath
                ? decodeURIComponent(to.query.redirectAuthenticatedPath as string)
                : '/';
        }

        return;
    }

    if (to?.name && !SECURED_ROUTE_NAMES.includes(to.name as string)) {
        return;
    }

    return signIn(
        'znlzn',
        {callbackUrl: to.path},
        {redirectAuthenticated: '1', redirectAuthenticatedPath: to.path}
    ) as ReturnType<typeof navigateTo>;
});
