import validate from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/vercel/path1/middleware/authentication.global.ts";
import trailing_45slash_45global from "/vercel/path1/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/vercel/path1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path1/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}